/**   -------------   NAV   ------------   **/

.ProductsRoute-nav {
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  margin-top: 1rem;
}
.ProductsRoute-nav_info {
  width: 60%;
}
.ProductsRoute-nav_new {
  width: 40%;
}
.pr-nav_btn--info {
  border: none;
  border-radius: 5px;
  background-color: var(--darkDarkBlue);
  color: #fff;
  font-weight: bold;
  padding: 0.3rem;
  margin: 0 2rem;
}
.pr-nav_btn--info:hover {
  background-color: var(--lightGray);
  color: var(--darkDarkBlue);
  outline: 1px solid var(--darkDarkBlue);
}
.pr-nav_btn--new {
  border: none;
  border-radius: 5px;
  background-color: var(--SuccessColor);
  color: #fff;
  font-weight: bold;
  padding: 0.3rem;
  margin: 0 2rem;
}
.pr-nav_btn--new:hover {
  background-color: var(--SuccessLightColor);
  color: #fff;
  outline: 1px solid var(--darkDarkBlue);
}

/**   NEWEST SECTION  **/

.productsRoute-subtitle {
  margin-top: 2rem;
  font-weight: bolder;
}
.productRoute-cards_container {
  width: 100%;
  min-height: 30rem;
  margin: 2rem 0;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
