@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300&family=Raleway:wght@500&display=swap");
.App {
  text-align: center;
  margin: 0;
  padding: 0;
  background-color: var(--lightGray);
  font-family: var(--FM-Quicksand);
}
.outline {
  min-height: 87vh;
}
:root {
  --blueDark: #003554;
  --blueSoft: #3c789a;
  --blue: #4e5170;
  --blueLight: #918195;
  --lightGreen: #c8bac0;
  --lightYellow: #e9e7e7;
  --lightGray: #cac6c5;
  --lightlightYellow: #f3f3f3;
  --darkDarkBlue: #0664a4;
  --blueV4: #00a6ff;
  --ErrorColor: #730018;
  --ErrorColorLight: #db000a;
  --ErrorColorUltraLight: #feb4b4;
  --SuccessColor: #0a7819;
  --SuccessLightColor: #1fac33;
  --warningColor: #d5b212;
  --warningLightColor: #ffd515;
  --FM-Quicksand: "Quicksand", sans-serif;
  --FM-Raleway: "Raleway", sans-serif;
}

/** ============================== GENERAL CLASSES ============================== **/

.routeContainer {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  margin-top: 1rem;
  font-weight: bolder;
}

.img-description {
  width: 12rem;
  height: 14rem;
  object-fit: cover;
  border-radius: 10px;
}

/**   ------------------ CONTAINERS   ------------------   **/

.container-cards {
  width: 90%;
  min-height: 30rem;
  margin-top: 2rem;
  border-radius: 10px;
  background-color: #fff;
}
.container-cards-small {
  width: 90%;
  min-height: 10rem;
  margin: 2rem;
  padding: 1rem;
  border-radius: 10px;
  background-color: #fff;
}

.container-cards_row {
  width: 90%;
  min-height: 30rem;
  margin-top: 2rem;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}
.container-cards-row_small {
  width: 90%;
  min-height: 10rem;
  margin-top: 2rem;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.container-flex-around {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 1rem;
}

.section-cards-flex-wrap {
  width: 90%;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 2rem 0;
  border-radius: 10px;
}

.background-white {
  background-color: #fff;
}

/**   ------------------ FORMS   ------------------   **/

.form-container {
  width: 80%;
  padding: 1rem;
  margin: 1rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
}

.form_label {
  font-weight: bold;
  margin: 0.5rem;
}
.form_input {
  width: 35rem;
  height: 2rem;
  margin: 0.5rem;
  border: none;
  border-radius: 10px;
  background-color: var(--lightGreen);
  color: var(--blueDark);
  font-weight: bolder;
}

.form_textarea {
  width: 35rem;
  height: 6rem;
  margin: 0.5rem;
  resize: none;
  border: none;
  border-radius: 10px;
  background-color: var(--lightGreen);
  color: var(--blueDark);
  font-weight: bolder;
}

.form_input-error {
  outline: 2px solid red;
}

.form_input-error--p {
  font-weight: bold;
  color: red;
}

/**   ------------------ BUTTONS   ------------------   **/

.button-blue,
.button-warn,
.button-success,
.button-grayBlue,
.button-no,
.button-disable {
  margin: 0.5rem;
  padding: 0.3rem;
  min-width: 5rem;
  border: none;
  border-radius: 10px;
  background-color: var(--darkDarkBlue);
  color: #fff;
  font-weight: bold;
}
.button-warn {
  background-color: var(--warningColor);
}
.button-no {
  background-color: var(--ErrorColorLight);
}
.button-success {
  background-color: var(--SuccessColor);
}

.button-grayBlue {
  background-color: var(--blue);
}
.button-blue:hover {
  background-color: var(--lightGray);
  color: var(--darkDarkBlue);
  outline: 1px solid var(--darkDarkBlue);
}

.button-warn:hover {
  background-color: var(--warningLightColor);
  outline: 1px solid var(--warningColor);
}
.button-no:hover {
  background-color: var(--ErrorColor);
  outline: 1px solid var(--ErrorColorLight);
}

.button-grayBlue:hover {
  background-color: var(--blueLight);
  outline: 1px solid var(--blue);
}

.button-success:hover {
  background-color: var(--SuccessLightColor);
  outline: 1px solid var(--SuccessColor);
}
.button-disable {
  cursor: default;
  background-color: var(--lightGreen);
  color: gray;
}

/**   ----------------  PAGINATION  ------------------  **/

.pagination-section {
  width: 90%;
  background-color: #fff;
  margin: 2rem;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

/**    CONTAINER ERRORS     **/

.alert-error-container {
  padding: 0.4rem 1rem;
  margin: 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--ErrorColorUltraLight);
  border-radius: 10px;
}
.text-error {
  font-weight: bolder;
  color: var(--ErrorColorLight);
}

/**    CONTAINER SUCCESS       **/
.alert-success-container {
  margin: 1rem 0;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--SuccessLightColor);
  border-radius: 10px;
}

.text-alert-success {
  margin: auto;
  font-weight: bolder;
  color: var(--lightGreen);
}

/**           MODAL CONTAINERS          **/
.modal-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.7567401960784313) 0%,
    rgba(26, 26, 29, 0.6699054621848739) 0%,
    rgba(0, 0, 0, 0.7259278711484594) 100%
  );
  z-index: 10000;
}
.modal-text {
  margin-top: 1rem;
  font-weight: bolder;
  color: var(--lightYellow);
}

/*       -------------- MEDIA QUERIES --------------        */

@media (max-width: 750px) {
  /**FORM **/

  .form_input {
    width: 80%;
  }
}
