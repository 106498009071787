.sellProduct-container {
  background-color: var(--lightGray);
  min-height: 100vh;
  padding: 1rem;
}
.sellProduct-title {
  margin-top: 2rem;
  font-weight: bold;
}

/**   SELL PRODUCT FORM   **/

.sellForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
  background-color: #fff;
  border-radius: 15px;
}

.sellForm-textarea {
  width: 35rem;
  height: 2rem;
  margin: 0.5rem;
  border: none;
  border-radius: 10px;
  background-color: var(--lightGreen);
  color: var(--blueDark);
  font-weight: bolder;
}
.sellForm-textarea {
  width: 35rem;
  height: 20rem;
  resize: none;
}

.sellForm-previewImg_container {
  width: 15rem;
}
.sellForm-previewImg_img {
  width: 15rem;
  margin: 1rem;
  border-radius: 10px;
}
.sellForm-previewImg_cross {
  width: 3rem;
  position: relative;
  bottom: 10%;
  left: 70%;
  z-index: 10;
  cursor: pointer;
}

.sellForm--button {
  margin: 2rem;
  padding: 0.5rem;
  border: none;
  border-radius: 10px;
  background-color: var(--SuccessColor);
  color: #fff;
  font-weight: bolder;
}
.sellForm--button:hover {
  background-color: var(--SuccessLightColor);
  overflow: 2px solid var(--SuccessColor);
}

/**  ERROR SECTION **/
.sellProduct-error {
  width: 90%;
  background-color: var(--ErrorColorLight);
  border-radius: 10px;
  margin: auto;
  padding: 1rem;
}

.sellProduct-error_text {
  font-weight: bolder;
  color: var(--lightYellow);
}
