.panel-header {
  width: 100%;
  height: 3rem;
  background-color: var(--blueSoft);
}
.panel-nav {
  display: flex;
  justify-content: space-around;
  background-color: var(--blueSoft);
  width: 100%;
  height: 4rem;
  border-top: 2px solid var(--blueDark);
  border-bottom: 2px solid var(--blueDark);
}

.panel-header_title {
  color: #fff;
  font-weight: bolder;
}
.panel-body {
  width: 90%;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.usertarget-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 90%;
  min-height: 6rem;
  margin: 2rem 0;
  border-radius: 10px;
  background-color: var(--blueLight);
}

.usertarget-data,
.usertarget-rol {
  text-align: start;
}
.usertarget-options {
  max-width: 50%;
}

.usertarget-p {
  font-weight: bolder;
  color: #fff;
}
.usertarget-b {
  color: var(--blueDark);
}

/** SUPERUSER CARDS  **/
.su-card {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  border-top: 1px solid var(--blueDark);
  border-bottom: 4px solid var(--blueDark);
  background-color: var(--lightGreen);
}
.su-divisor {
  width: 25%;
  text-align: center;
}
.su-p {
  font-weight: bolder;
  color: var(--blueDark);
}

/**  LOGS CARD  **/
.logs-card {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  border-top: 1px solid var(--blueDark);
  border-bottom: 4px solid var(--blueDark);
  background-color: var(--lightGreen);
}
.logs-divisor {
  width: 33%;
  text-align: center;
}

.logs-divisor p {
  font-weight: bolder;
  color: var(--blueDark);
}
.logs-divisor b {
  color: var(--blueSoft);
}
/*  ----------   MEDIA QUERIES ----------- */
@media (max-width: 900px) {
  .usertarget-container {
    flex-direction: column;
    align-items: center;
  }
  .usertarget-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
}
@media (max-width: 800px) {
  .logs-card {
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 500px) {
  .logs-divisor p,
  .logs-divisor b {
    font-size: 0.8rem;
  }
}
