.productCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 1rem;
  border-radius: 10px;
  padding: 1rem;
  max-width: 14rem;
  height: 24rem;
}
.productCard:hover {
  background-color: var(--lightlightYellow);
  cursor: pointer;
}
.productCard-container_name {
  align-self: flex-start;
  height: 4rem;
}

.productCard-name {
  font-weight: bold;
}
.productCard-img {
  width: 10rem;
  height: 12rem;
  border-radius: 5px;
  object-fit: cover;
  align-self: center;
}

.productCard-container_desc {
  position: relative;
  bottom: 0%;
}
.productCard-price {
  margin: 0.5rem;
}
