.navbarContainer {
  background-color: var(--blueDark);
  border-bottom: 2px solid var(--blueLight);
}
.navbarContainer li {
  font-family: var(--FM-Quicksand);
  font-weight: bolder;
}
.nav-li_userIcon {
  display: flex;
  cursor: pointer;
  margin-right: 4rem;
}

.navbar-userIcon {
  width: 1.5rem;
}
.dropdown-menu,
.dropdown-item {
  background-color: var(--blueDark) !important;
  font-weight: bolder;
  color: #fff;
}
.dropdown-item:hover {
  background-color: var(--blueLight) !important;
}
.btnNavbar {
  font-family: var(--FM-Quicksand);
  border: none;
  border-radius: 10%;
  background-color: var(--darkDarkBlue);
  color: #fff;
  font-weight: bold;
}
.btnNavbar:hover {
  background-color: var(--lightGray);
  color: var(--darkDarkBlue);
  outline: 1px solid var(--darkDarkBlue);
}
.sharknet-logo {
  width: 4rem;
  margin-left: 2rem;
}

/*        MEDIA QUERIES        */

@media (max-width: 991px) {
  .nav-li_userIcon {
    margin-left: auto;
    margin-right: auto;
  }
}
