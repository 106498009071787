.cart-form {
  margin: 2rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cart-form select {
  width: 40%;
  height: 2rem;
  margin: 0.5rem;
  border: none;
  border-radius: 10px;
  background-color: var(--lightGreen);
  color: var(--blueDark);
  font-weight: bolder;
}
.cart-form label {
  font-weight: bold;
  margin: 0.5rem;
}

/**  MODAL  **/
.cartModal {
  background-color: #fff;
  padding: 2rem;
  border-radius: 10px;
}
