.containerError {
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 10px;
}
.notfound-img {
  width: 15rem;
}
.containerError h3 {
  font-weight: bolder;
}

@media (max-width: 500px) {
  .notfound-img {
    width: 10rem;
  }
  .containerError h3 {
    font-size: 1rem;
  }
}
