.salesCard {
  width: 100%;
  min-height: 5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin: 1rem 0;
  padding: 0.5rem;
  background-color: #fff;
  border-bottom: 4px solid var(--blueLight);
}
.salesCard-buyer,
.salesCard-product,
.salesCard-amount,
.salesCard-rate {
  width: 10rem;
  height: 100%;
  text-align: start;
}
.salesCard-img_container {
  height: 100%;
  width: 5rem;
  text-align: start;
}

.salesCard-img {
  width: 4rem;
  object-fit: cover;
}

.salesCard-rate {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.salesCard-p {
  color: black;
  font-weight: bolder;
}

.salesCard-b {
  color: var(--blueV4);
}

/*    -------------   MEDIA QUERIES  ----------------     */

@media (max-width: 750px) {
  .salesCard {
    flex-direction: column;
    align-items: center;
  }
  .salesCard-img {
    margin-bottom: 1rem;
  }
} ;
