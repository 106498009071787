.buysCard {
  width: 100%;
  min-height: 5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin: 1rem 0;
  padding: 0.5rem;
  background-color: var(--blueSoft);
  border-top: 1px solid var(--blueLight);
  border-bottom: 4px solid var(--blueLight);
}
.buysCard-buyer,
.buysCard-product,
.buysCard-amount,
.buysCard-rate {
  width: 10rem;
  height: 100%;
  text-align: start;
}

.buysCard-rate {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buysCard-p {
  color: var(--lightYellow);
  font-weight: bolder;
}

.buysCard-b {
  color: var(--blueDark);
}

/*    -------------   MEDIA QUERIES  ----------------     */

@media (max-width: 750px) {
  .buysCard {
    flex-direction: column;
    align-items: center;
  }
} ;
