.LoaderPage-container {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  background-color: var(--lightGreen);
}
