.desc-img_container {
  width: 100%;
  height: 20rem;
  display: flex;
  justify-content: center;
}
.card-img_desc {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.desc-img_img {
  width: 14rem;
  height: 16rem;
  object-fit: cover;
  border-radius: 10px;
}
