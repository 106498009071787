.loginRoute-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--FM-Quicksand);
}
.loginRoute-title {
  margin-top: 2rem;
  font-weight: bolder;
}

.form-login {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}
.loginRoute-container button {
  border: none;
  border-radius: 5px;
  background-color: var(--darkDarkBlue);
  color: #fff;
  font-weight: bold;
  padding: 0.3rem;
  margin: 2rem;
}
.loginRoute-container input {
  border-radius: 5%;
  border: none;
  padding: 0.3rem;
  background-color: var(--blueLight);
  color: #fff;
  font-weight: bold;
}
.loginRoute-container label {
  font-weight: bolder;
}
.loginRoute-container button:hover {
  background-color: #6a5681;
  outline: 1px solid #6a39a2;
}
.error-login {
  margin-top: 1rem;
  font-weight: bolder;
  color: red;
}
